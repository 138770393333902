import Vue from 'vue'
import App from './App.vue'
import Footer from './components/Footer.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/css/scrolling-nav.css'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
}).$mount('#app')

new Vue({
  render: h => h(Footer)
}).$mount('#footer')
