<template>
  <div>
    <span>
      <p
        id="datenow"
        class="m-0 text-center text-white"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  }
}

window.onload = function () {
  const currentyear = 'Copyright &copy; MasterWayZ 2020 - ' + new Date().getFullYear()
  document.getElementById('datenow').innerHTML = currentyear
}
</script>

<style lang="css">

</style>
