<template>
  <div class="col-md-12 mb-3">
    <form @submit.prevent="processcreate">
      <h2>Get GIFs from the GIF API.</h2>
      <p>Fill in the type of GIF and the amount, and we'll get them to you!</p>
      <b>Note that the GIFs in some catagories are a mix-match of SFW and NSFW.</b><br>
      <label>
        Category: <br>
        <select
          v-model="form.query">
          <optgroup label="Generic">
            <option>hugs</option>
            <option value="wonderwoman">wonder woman</option>
            <option>kiss</option>
          </optgroup>
          <optgroup label="NSFW">
            <option>ekiss</option>
          </optgroup>
        </select>
      </label> <br>
      <label>
        Amount (max 5): <br>
        <input
          v-model="form.amount"
          type="number"
          min="1"
          max="5"
        >
      </label> <br>
      <button type="submit">
        Submit
      </button>
    </form>
    <br />

    <div v-for="(gif, index) in gif_list" :key="index">
      <img :src="gif.gif" />
    </div>
    <br>
    <p id="error"></p>
  </div>
</template>

<script>
export default {
  data:
    function () {
        return {
            form: {
                query: '',
                amount: '',
            },
            gif_list: []
        }
    },
    methods: {
        processcreate: async function () {
          this.gif_list = ''
          this.error = ''
          document.getElementById('error').innerHTML = ''
          const { amount } = this.form
          if (amount == 0 || amount > 5) {
            document.getElementById('error').innerHTML = 'This error should not appear. Were you messing around in DevTools?'
            return
          }
          await this.actualrequest(this.form)
        },
        actualrequest: async function(data) {
          const response = await this.axios.post('https://gifapi-workers-api.masterwayz.nl/', data)
          if ( response.data.some(error => error.message === 'Internal Server Error') ) {
            document.getElementById('error').innerHTML = 'Either something went wrong on the backend, or there are no GIFs of teh selected type.'
            return
          }
          this.gif_list = response.data
        },
    }
}
</script>

<style>
img {
  float: left;
  max-width: 70vw;
  height: auto;
  margin: 10px;
}

video {
  float: left;
  max-width: 70vw;
  height: auto;
  margin: 10px;
}
</style>